import React, { Component, useState, useEffect, useMemo } from 'react';
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil';

import loadable from "@loadable/component";

import {
  BrowserRouter,
  Routes,
  createBrowserRouter,
  RouterProvider,
  Route,
  Navigate,
  useNavigate,
  useLocation,
  Link
} from 'react-router-dom';
import { withTranslation, Translation, } from 'react-i18next';

import { isLogin, profileExists, isReservedPath } from './utils';
import i18n from "./i18n";

import LoadingPage from "./components/pages/home/LoadingPage";


import { isLoggedin, userState, authState } from "./store/stateData";

const algoliasearch = require('algoliasearch');
var client = algoliasearch('0MHBU0LWTD', '529e3e0d75aa7888718f784a83d4aad4');


const viewportWidth = window.innerWidth;
const viewportHeight = window.innerHeight;

const HeaderConnectedPC = loadable(() => import("./components/header/HeaderConnectedPC"));


const HomePage = loadable(() => import("./components/pages/home/HomePage"), {
   fallback: <LoadingPage />
});

const HomeFeedPage = loadable(() => import("./components/pages/home/HomeFeedPage"), {
       fallback: <LoadingPage />
});

const JobPage = loadable(() => import("./components/pages/jobs/JobPage"), {
       fallback: <LoadingPage />
});


const EmbedPage = loadable(() => import("./components/pages/jobs/EmbedPage"), {
       fallback: <LoadingPage />
});


const PreviewPage = loadable(() => import("./components/pages/jobs/PreviewPage"), {
       fallback: <LoadingPage />
});

const EditPreviewPage = loadable(() => import("./components/pages/jobs/EditPreviewPage"), {
       fallback: <LoadingPage />
});

const SearchPage = loadable(() => import("./components/pages/jobs/SearchPage"), {
       fallback: <LoadingPage />
});

const SalesformPage = loadable(() => import("./components/pages/payment/SalesformPage"), {
   fallback: <LoadingPage />
});

const PaymentPage = loadable(() => import("./components/pages/payment/PaymentPage"), {
   fallback: <LoadingPage />
});

const PreorderPage = loadable(() => import("./components/pages/payment/PreorderPage"), {
   fallback: <LoadingPage />
});


const CancelpaymentPage = loadable(() => import("./components/pages/payment/CancelpaymentPage"), {
   fallback: <LoadingPage />
});

const AcceptpaymentPage = loadable(() => import("./components/pages/payment/AcceptpaymentPage"), {
   fallback: <LoadingPage />
});

const DeclinepaymentPage = loadable(() => import("./components/pages/payment/DeclinepaymentPage"), {
   fallback: <LoadingPage />
});


const ContactPage = loadable(() => import("./components/pages/general/ContactPage"), {
       fallback: <LoadingPage />
});

const SupportPage = loadable(() => import("./components/pages/general/SupportPage"), {
       fallback: <LoadingPage />
});

const TermsPage = loadable(() => import("./components/pages/general/TermsPage"), {
       fallback: <LoadingPage />
});

const PrivacyPage = loadable(() => import("./components/pages/general/PrivacyPage"), {
       fallback: <LoadingPage />
});



const NoMatch = loadable(() => import("./components/pages/home/NoMatch"), {
   fallback: <LoadingPage />
});


const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <HomePage/>
    ),
  },
  {
    path: "about",
    element: <div>About</div>,
  },
]);

/*
<RouterProvider router={router} />
*/

const ProtectedRoute = ({ isSignedin, children }) => {

  if (!isSignedin) {
    return <Navigate to="/" replace />;
  }

  return children;
};

const PublicRoute = ({ isSignedin, children }) => {

  if (isSignedin) {
    return <Navigate to="/home" replace />;
  }

  return children;
};

const runMemorizeCards = cards => {
  console.log('>>> run runMemorizeCards :: ' + cards.length);
  return cards
}

const runMemorizeBillingaddrs = billaddrs => {
  console.log('>>> run runMemorizeBillingaddrs :: ' + billaddrs.length);
  return billaddrs
}

const runMemorizeJobs = jobs => {
  console.log('>>> run runMemorizeJobs :: ' + jobs.length);
  return jobs
}

const runMemorizePayments = payments => {
  console.log('>>> run runMemorizePayments :: ' + payments.length);
  return payments
}

const runMemorizeNotifications = notifs => {
  console.log('>>> runMemorizeNotifications :: ' + notifs.length);
  return notifs
}

const SIZE_PER_PAGE = 30;

const TOKEN_KEY = 'jwtToken';

var ws;

const App = () => {
      let isTypingTimeout;
      const currentYear = new Date().getFullYear();
      const [isSignedin, setIsSignedin] = useRecoilState(isLoggedin);
      const [sinUser, setSinUser] = useRecoilState(userState);
      const [authStatus, setAuthStatus] = useState('init');

      const [parentHash, setParentHash] = useState(null);

      const [newMessage, setNewMessage] = useState(null);
      const [newRoom, setNewRoom] = useState(null);

      const [openedTotal, setOpenedTotal] = useState(0);
      const [resolvedTotal, setResolvedTotal] = useState(0);
      const [debyooTotal, setDebyooTotal] = useState(0);
      const [userTotal, setUserTotal] = useState(0);
      const [isTyping, setIsTyping] = useState(null);

      const [reloadPage, setReloadPage] = useState(null);
      const [totalRequests, setTotalRequests] = useState(0);
      const [totalDreamers, setTotalDreamers] = useState(0);
      const [totalInitiators, setTotalInitiators] = useState(0);
      const [totalElders, setTotalElders] = useState(0);
      const [debyooEmployees, setDebyooEmployees] = useState(0);
      const [visitors, setVisitors] = useState(0);
      const [members, setMembers] = useState(0);
      const [nonmembers, setNonmembers] = useState(0);
      const [notsignedins, setNotsignedins] = useState(0);
      const [mdreamers, setMdreamers] = useState(0);
      const [minitiators, setMinitiators] = useState(0);
      const [melders, setMelders] = useState(0);
      const [reqdreamers, setReqdreamers] = useState(0);
      const [reqinitiators, setReqinitiators] = useState(0);
      const [reqelders, setReqelders] = useState(0);
      const [mcountries, setMcountries] = useState([]);
      const [rippleringsData, setRippleringsData] = useState([]);
      const [rippleringsLat, setRippleringsLat] = useState(0);
      const [rippleringsLng, setRippleringsLng] = useState(0);
      const [maplabelsData, setMaplabelsData] = useState([]);

      const [iactiveposts, setIactiveposts] = useState(0);
      const [ipendingposts, setIpendingposts] = useState(0);
      const [iviewedposts, setIviewedposts] = useState(0);
      const [iclickedposts, setIclickedposts] = useState(0);

      const [receivedACK, setReceivedACK] = useState(null);
      const [seenACK, setSeenACK] = useState(null);

      const [jobs, setJobs] = useState([]);
      const jobsResult = useMemo(() => runMemorizeJobs(jobs), [jobs]);

      const [totalNotifications, setTotalNotifications] = useState(0);

      const [notifications, setNotifications] = useState([]);
      const notificationsResult = useMemo(() => runMemorizeNotifications(notifications), [notifications]);


      const [cards, setCards] = useState([]);
      const cardsResult = useMemo(() => runMemorizeCards(cards), [cards]);

      const [billaddrs, setBilladdrs] = useState([]);
      const billingaddrsResult = useMemo(() => runMemorizeBillingaddrs(billaddrs), [billaddrs]);

      const [payments, setPayments] = useState([]);
      const paymentsResult = useMemo(() => runMemorizePayments(payments), [payments]);

      let urlBool;
      if (window.location.pathname.includes('/_pr') || window.location.pathname.includes('/_previewpr')) {
        urlBool = true;
      } else if (window.location.pathname.includes('/search')) {
        urlBool = true;
      } else {
        urlBool = false;
      }

      const urlMatch = urlBool;



      const url = window.location.search;
      const queryParams = new URLSearchParams(url);
      let qrAfter = queryParams.get('after');

      if (qrAfter)
      {
         localStorage.setItem("after", qrAfter);
         localStorage.setItem("params", queryParams);

         //alert(localStorage.getItem("after"))
        // alert(localStorage.getItem("params"))
      }

      //alert(match)



      useEffect(() => {



        /*async function signalChatSendersThatIReceivedMessages() {

            try {

                let url = `https://bjhg2qil37.execute-api.us-east-1.amazonaws.com/prod/ihavereceivedmessages?uid=Debyoo&t=all`;

                let response = await fetch(url, {method: 'POST'});

                let res = await response.json();

              //  console.log('>> App index ihavereceivedmessages res >> ' + JSON.stringify(res));

                return res;
            }
            catch(error)
            {
               return error;
            }
          }
          signalChatSendersThatIReceivedMessages();*/



          //i18n.changeLanguage('en');



          fetch('https://api.ipstack.com/check?access_key=9cd4ffbf45a257de9e644d4d875a9465')
           		  	.then((response) => response.json())
           		  	.then((responseData) => {
           			  	   //alert("App. Store: " + JSON.stringify(responseData));

                       global.country = responseData.country_code;
                       global.city = responseData.city;
                       global.region = responseData.region_name;
                       global.continent = responseData.continent_code;
                       global.ip = responseData.ip;

           		      })
           		     .catch(function(error) {

           		     });



      }, []);



      useEffect(() => {

    //  console.log(JSON.stringify(sinUser));

        if (isSignedin && sinUser !== null)
        {

             let user= JSON.parse(sinUser);

             authConnection(user);

             countNewNotifs(user);

            // alert(JSON.stringify(user))



              var jwtToken = user.jwt_token; //localStorage.getItem("jwtToken") || '';

              loadYIJTotalRequests(jwtToken);



              fetch('https://api0.debyoo.com/check_cdpresse_auth_grant_api', {
                                        method: 'POST',
                                        headers: {
                                          'Accept': 'application/json',
                                           'Authorization': jwtToken,
                                          'Content-Type': 'application/json'
                                        },
                                         credentials: 'include',
                                        body: JSON.stringify({
                                          auth_check: 'jwt',
                                          access_token: user.access_token,
                                          typef: 'global'
                                        })
                                })
                  .then((response) => response.json())
                  .then((responseData) => {


                         //alert(JSON.stringify(responseData))



                         if (responseData.status == "success")
                         {


                               setAuthStatus(responseData.cdpresse_auth_grants);

                               if (responseData.parent_uid !== undefined && responseData.parent_uid !== null && responseData.parent_uid !== '')
                               {
                                 let hash = {
                                   parent_name: responseData.parent_name,
                                   parent_uid: responseData.parent_uid,
                                   parent_picture: responseData.parent_picture,
                                   parent_email: responseData.parent_email,
                                 }
                                 setParentHash(hash);

                                  loadJobs2(responseData.parent_uid);
                               }
                               else
                               {
                                   let user= JSON.parse(sinUser);
                                   loadJobs2(user.uid);
                               }



                         }
                         else
                         {

                             setAuthStatus('0');
                         }


                     })
                   .catch(function(error) {

                   }.bind(this));



                   // Noitifications
                   loadNotifs();


                   // Cards - Payment methods
                   loadCards();


                   // Billing addresses
                   loadBillingaddrs();

                   // Payments
                   loadPayments();


          }
          else
          {
                localStorage.removeItem('jwtToken');
                localStorage.removeItem('dcUser');

                setTimeout(() => {
                  // window.location = "/";
                },100);
          }


   }, []);


   const loadPayments = async () => {


         let user= JSON.parse(sinUser);
         let uid = user.uid;

        // alert(JSON.stringify(parentHash))
         if (parentHash !== null)
         {
           uid = parentHash.parent_uid;
         }

        //alert(user.uid + ' / ' + parentHash.parent_uid)

         client.clearCache();

        //alert(uid)

        setTimeout(() => {

            var index = client.initIndex('Transaction');

            //console.log('>>> Goooo search algolia');



            index
            .search('*',
            {
              page: 0,
             filters: `parent_uid:${uid}`,
             hitsPerPage: SIZE_PER_PAGE
            })
            .then(({ hits, nbPages, page, nbHits }) =>
              {


                 setPayments(hits);

             });

         },2000);

   }



   const loadJobs = async () => {


         let user= JSON.parse(sinUser);
         let uid = user.uid;

        // alert(JSON.stringify(parentHash))
         if (parentHash !== null)
         {
           uid = parentHash.parent_uid;
         }

        //alert(user.uid + ' / ' + parentHash.parent_uid)

         client.clearCache();

        //alert(uid)

        setTimeout(() => {

            var index = client.initIndex('Cdpresse');

            //console.log('>>> Goooo search algolia');

            index
            .search('*',
            {
              page: 0,
             filters: `parent_uid:${uid}`,
             hitsPerPage: SIZE_PER_PAGE
            })
            .then(({ hits, nbPages, page, nbHits }) =>
              {

                 setJobs(hits);

             });

         },2000);

   }


   const loadJobs2 = async (uid) => {

         client.clearCache();

         setTimeout(() => {

             var index = client.initIndex('Cdpresse');

             //console.log('>>> Goooo search algolia');

             index
             .search('*',
             {
               page: 0,
              filters: `parent_uid:${uid}`,
              hitsPerPage: SIZE_PER_PAGE
             })
             .then(({ hits, nbPages, page, nbHits }) =>
               {
                  setJobs(hits);

              });

           },2000);

   }

   const loadCards = async () => {

            let user= JSON.parse(sinUser);

            var jwtToken = user.jwt_token;

           var ip =  "https://api1.debyoo.com/"; //generateIPForREST();


           fetch(ip+'load_user_payment_method_cards_api', {
                  method: 'POST',
                  headers: {
                    'Accept': 'application/json',
                    'Authorization': jwtToken,
                    'Content-Type': 'application/json'
                  },
                  credentials: 'include',
                  body: JSON.stringify({
                    auth_check: 'jwt',
                    a: user.uid,
                   typef: 'new billing',
                   lang: 'fr'
                  })
               })
             .then((response) => response.json())
             .then((responseData) => {

               if (responseData.status == "success")
               {
                     if (responseData.cards.length == 0)
                     {
                           /*  var _itemToBuy = this.state.itemToBuy;
                             var _paymentHash = {
                               payment_exists: "no",
                               payment_id: '',
                               payment_method: '',
                               payment_typef: '',
                               payment_option: ''
                             };

                             _itemToBuy.paymentHash = _paymentHash;*/

                              setCards(responseData.cards);


                     }
                     else
                     {

                            var _arr = [];

                             {responseData.cards.map(function(card, i) {

                                  if (card.pm_id !== "")
                                  {

                                    _arr.push(card);
                                  }

                                  if (i == responseData.cards.length-1)
                                  {
                                    //console.log('>>> Payments :: ' + JSON.stringify(_arr));

                                    setCards(_arr);


                                  }


                             }, this)}


                      }

               }


              })
              .catch(function(error) {

              }.bind(this));

   }

   const loadBillingaddrs = () => {


           let user= JSON.parse(sinUser);

           var jwtToken = user.jwt_token;

          var ip =  "https://api0.debyoo.com/"; //generateIPForREST();


          fetch(ip+'load_user_billing_addr_api', {
                 method: 'POST',
                 headers: {
                   'Accept': 'application/json',
                   'Authorization': jwtToken,
                   'Content-Type': 'application/json'
                 },
                 credentials: 'include',
                 body: JSON.stringify({
                   auth_check: 'jwt',
                   a: user.uid
                 })
              })
            .then((response) => response.json())
            .then((responseData) => {



                  if (responseData.status == "success")
                  {

                        setBilladdrs(responseData.addrs);

                  }


             })
             .catch(function(error) {

             }.bind(this));

   }


   const loadNotifs = async () => {



             let user= JSON.parse(sinUser);



             let hash = {
               puid: user.cdpresse_account_parent_uid,
               uid: user.uid,
               limit: 20,
               offset: 0
             };

             try {

               // loadusernotifications loadappnotifications

                 let url = `https://bjhg2qil37.execute-api.us-east-1.amazonaws.com/prod/loadusernotifications?puid=${hash.puid}&uid=${hash.uid}&l=${hash.limit}&o=${hash.offset}&a=CdPresse`;



                 let response = await fetch(url, {method: 'POST'});

                 let res = await response.json();



                 setNotifications(res.data)

               //  return res;
             }
             catch(error)
             {
                 setNotifications([])
             }

   }

   const reloadJobs = (type, id, attr ,val) => {
          console.log('>> reloadJobs >> reloadCdpresses');
          if (type === 'all')
          {
               loadJobs();
          }
          else
          {
              // alert(type + ' / ' + id + ' / ' + attr + ' / ' + val);

              if (attr === 'object' && val === 'delete')
              {

                  const newArray = jobs.filter(item => item.objectID !== id);

                  setJobs(newArray);
              }
              else if (attr === 'isPromoted')
              {

                  const newArray = jobs.map(function(m, index) {
                     if (m.objectID === id)
                     {
                        m.isPromoted = true;
                     }

                     return m
                  });

                  setJobs(newArray);
              }
              else
              {

                   const newArray = jobs.map(function(m, index) {
                      if (m.objectID === id)
                      {
                         m.status = val;
                      }

                      return m
                   });

                   setJobs(newArray);

              }
          }
   }

   const reloadPayments = () => {
      console.log('>>> reloadPayments');

      loadPayments();
   }

   const reloadNotifications = () => {
          console.log('>> reloadNotifications');
          loadNotifs();
   }

   const handleResetUserNotifications = async () => {
       let user= JSON.parse(sinUser);

       setTotalNotifications(0);

       try {



            let url = `https://bjhg2qil37.execute-api.us-east-1.amazonaws.com/prod/resetusernotifications?uid=${user.uid}&a=CdPresse`;

            let response = await fetch(url, {method: 'POST'});

            let res = await response.json();



        }
        catch(error)
        {

        }
   }

   const reloadBillingaddrs = (hash) => {
       console.log('>> reloadBillingaddrs');

       if (hash)
       {
                let arr = billaddrs;

                if (hash.typef === 'new billing')
                {


                    hash.is_default = true;
                    hash.billing_address_is_default = "1";

                    let newArray = arr.map((m, index) => {
                        m.is_default = false;
                        m.billing_address_is_default = "0";

                       return m
                    });

                    newArray.unshift(hash);

                    setBilladdrs(newArray);
                }
                else if (hash.typef === 'old billing')
                {
                    const newArray = arr.map((m, index) => {
                       if (m.billing_address_id === hash.billing_address_id)
                       {
                           m.is_default = true;
                           m.billing_address_is_default = "1";
                       }
                       else
                       {
                          m.is_default = false;
                          m.billing_address_is_default = "0";
                       }

                       return m
                    });

                    setBilladdrs(newArray);
                }


       }
       else
       {

               loadBillingaddrs();

        }

   }


   const reloadCards = (hash) => {

              console.log('>> reloadCards');

              if (hash)
              {
                       //alert(JSON.stringify(cards));

                       let arr = cards;

                       if (hash.typef === 'new card')
                       {

                           hash.is_default = true;
                           hash.payment_is_default = "1";

                           let newArray = arr.map((m, index) => {
                              m.is_default = false;
                              m.payment_is_default = "0";

                              return m
                           });

                           newArray.unshift(hash);

                           setCards(newArray);
                       }
                       else if (hash.typef === 'old card')
                       {
                           const newArray = arr.map((m, index) => {
                              if (m.payment_id === hash.payment_id)
                              {
                                  m.is_default = true;
                                  m.billing_address_is_default = "1";
                              }
                              else
                              {
                                 m.is_default = false;
                                 m.billing_address_is_default = "0";
                              }

                              return m
                           });

                           setCards(newArray);
                       }

              }
              else
              {


                       var ip =  "https://api1.debyoo.com/"; //generateIPForREST();


                       let user= JSON.parse(sinUser);


                        var jwtToken = user.jwt_token; //localStorage.getItem("jwtToken") || '';


                       fetch(ip+'load_user_payment_method_cards_api', {
                              method: 'POST',
                              headers: {
                                'Accept': 'application/json',
                                'Authorization': jwtToken,
                                'Content-Type': 'application/json'
                              },
                              credentials: 'include',
                              body: JSON.stringify({
                                auth_check: 'jwt',
                                a: user.uid,
                               typef: 'new billing',
                               lang: 'fr'
                              })
                           })
                         .then((response) => response.json())
                         .then((responseData) => {

                           if (responseData.status == "success")
                           {
                                 if (responseData.cards.length == 0)
                                 {
                                       /*  var _itemToBuy = this.state.itemToBuy;
                                         var _paymentHash = {
                                           payment_exists: "no",
                                           payment_id: '',
                                           payment_method: '',
                                           payment_typef: '',
                                           payment_option: ''
                                         };

                                         _itemToBuy.paymentHash = _paymentHash;*/

                                          setCards(responseData.cards);


                                 }
                                 else
                                 {

                                        var _arr = [];

                                         {responseData.cards.map(function(card, i) {

                                              if (card.pm_id !== "")
                                              {

                                                _arr.push(card);
                                              }

                                              if (i == responseData.cards.length-1)
                                              {
                                                //console.log('>>> Payments :: ' + JSON.stringify(_arr));

                                                setCards(_arr);


                                              }


                                         }, this)}


                                  }

                           }


                          })
                          .catch(function(error) {

                          }.bind(this));

              }

   }


   const countNewNotifs = async (user) => {


       try {

            let url = `https://bjhg2qil37.execute-api.us-east-1.amazonaws.com/prod/loadusernotificationbadgecounts?uid=${user.uid}&a=CdPresse`;

            let response = await fetch(url, {method: 'GET'});

            let res = await response.json();

            let total = res.data;

            //alert(total)

            setTotalNotifications(total);



            //global.totalNewNotifs = total;

        }
        catch(error)
        {

        }

  }

  const handleAuthConnection = (user) => {

        ws = new WebSocket('wss://3x75xlu5a6.execute-api.us-east-1.amazonaws.com/production');



        ws.onopen = (e) => {




          let hash = {
             action: "setName",
             auth: "1",
             status: "first",
             yijm: "",
             yijmcateg: "",
             yijmcountry: "",
             name: user.name,
             uid: user.uid,
             city: global.city || '',
             region: global.region || '',
             country: global.country || '',
             continent: global.continent || '',
             app: "CdPresse",
             device: "mobile",
             deviceId: "",
             ip: global.ip,
             platform: "Web",
             deviceToken: ""
           };

           //console.log('>> +++++ send setName >> ' + JSON.stringify(hash));

           try {
              ws.send(JSON.stringify(hash));
            } catch (error) {

            }
       };
  }

   const reconnectWS = () => {
      // console.log('>>> App +++++ reconnectWS <<<<');

       setTimeout(() => {
      //  console.log('>>> HomeScreen +++++ reconnectWS <<<<');

        let user= JSON.parse(sinUser);

        authConnection(user);

    }, 5000);
   }


   const authConnection = (user) => {

      //console.log('>>> YOOOO CONNECTED');

          ws = new WebSocket('wss://3x75xlu5a6.execute-api.us-east-1.amazonaws.com/production');

          ws.onopen = (e) => {
           //    console.log('>>> YOOOO CONNECTED 2222');
            //console.log('>>> WebSocket authConnection connected :: ' + JSON.stringify(e));


          // alert(JSON.stringify(user))


            //console.log('>>> this.props.session.user >>> ' + JSON.stringify(this.props.session.user));
       //     {"action": "sendRequest", "id":"5", "type":"membership", "yijmcateg":"initiator","yijmcountry":"FR", "uid": "531d719cea31ac6ff5000003", "name": "Yves Muntu", "app": "YIJ"}

         //   {"action": "setName", "auth":"1", "yijm":"1", "yijmcateg":"initiator","yijmcountry":"TG", "name": "Suya Sudeli", "uid": "1", "city": "Brussels", "country": "BE", "continent": "EU", "app": "YIJ", "device": "mobile", "platform":"ios"}

         //   {"action": "setName", "auth":"1", "yijm":"1", "yijmcateg":"elder","yijmcountry":"BE", "name": "Jean Michel", "uid": "2", "city": "Brussels", "country": "BE", "continent": "EU", "app": "YIJ", "device": "mobile", "platform":"ios"}

           //console.log('>>> Send SetName');
            let hash = {
               action: "setName",
               auth: "1",
               name: user.name,
               uid: user.uid,
               parent_uid: user.cdpresse_account_parent_uid,
               city: "",
               country: "",
               continent: "",
               app: "CdPresse",
               device: "pc",
               deviceId: "desktop",
               platform: "cdpresse"
             };
           // connection opened
            ws.send(JSON.stringify(hash)); // send a message

         };

         ws.onclose = (e) => {
            ws = null;

            reconnectWS();
           //  console.log('>>> WebSocket disconnected :: '  + JSON.stringify(e));
             // connection closed
            //console.log(e.code, e.reason);
            //ws = new WebSocket('wss://1er7ek28k8.execute-api.us-east-1.amazonaws.com/production');
         };

         ws.onmessage  = (e) => {
          //  console.log('>>> WebSocket sent message ::: '  + e + ' >>> typeof : ' + typeof(e));
            let obj, data, res;
            if (typeof(e) === "string")
            {
               //console.log('>>> CASE 1');
                obj = JSON.parse(e);

                if (typeof(obj.data) === "string")
                {
                   data = JSON.parse(obj.data);

                   res = JSON.stringify(data);
                }
                else
                {
                  data = obj.data;

                  res = JSON.stringify(data);

                }
            }
            else
            {
             // console.log('>>> CASE 2');

              obj = e;

              if (typeof(obj.data) === "string")
              {
                 data = JSON.parse(obj.data);

                 res = JSON.stringify(data);
              }
              else
              {
                data = obj.data;

                res = JSON.stringify(data);

              }

            }

               //console.log('>>> WebSocket sent message - OBJECT DATA ::: '  + obj.data + ' >>> typeof : ' + typeof(obj.data));
               // console.log('>>> WebSocket sent message - DATA ::: '  + data + ' >>> typeof : ' + typeof(data));
             // console.log('>>> PC WebSocket sent message - res ::: '  + res + ' >>> typeof : ' + typeof(res));

              let type;
              if (typeof(res) === "string")
              {
                 res = JSON.parse(res);
                 type = res.type;
                 //console.log('>>> 1. type >>> ' + res.type);
              }
              else
              {
                type = res.type;
                //console.log('>>> 2. type >>> ' + res.type);
              }

            //  console.log('>> type :: ' + type);

          //alert('>>> +++++ CHCK >>> :: ' + JSON.stringify(res));

              if (type === "typing")
              {
                 //console.log('>>> +++++ Typing :: ' + JSON.stringify(res));
                 setIsTyping(res);
              }
              else if (type === "client")
              {

                  if (res.feature === "notification")
                  {
                      let user= JSON.parse(sinUser);

                      countNewNotifs(user);
                  }

              }
              else if (type === "widgets")
              {
                   //alert('>>> CdPress >>> ' + JSON.stringify(res.dataDCdpresses))

                   let data = res.dataDCdpresses;

                   setIactiveposts(data['iactiveposts']);
                   setIpendingposts(data['ipendingposts']);
                   setIviewedposts(data['iviewedposts']);
                   setIclickedposts(data['iclickedposts']);

                   /*setTimeout(() => {
                      setIactiveposts(19);
                   },3000);

                   setTimeout(() => {
                      setIclickedposts(26);
                   },6200);*/
              }
              else if (type === "debyoo")
              {

                   //console.log(`>>>> Alert message to Debyoo team :: ${JSON.stringify(res.requests)}`);
                  //  console.log('>>>> ++++ Feature ::: ' + res.feature);

                    if (res.feature === "support")
                    {
                        //alert('>>> +++++ Support Cases ICI DATA :: ' + JSON.stringify(res));
                        if (res.data !== null)
                        {
                            let data = res.data;

                            setOpenedTotal(data['openedTotal']);
                            setResolvedTotal(data['resolvedTotal']);
                            setDebyooTotal(data['debyooTotal']);
                            setUserTotal(data['userTotal']);

                       }

                       if (res.object === 'message')
                       {
                          // console.log('>> //////// Wss received new message :: ' + JSON.stringify(res.objectData));
                           let objectData = res.objectData;
                           objectData.operation = res.operation;

                           //console.log('>>> App operation - message :: ' + res.operation);

                           setNewMessage(objectData);

                           setTimeout(() => {
                             setNewMessage(null);
                           },500);

                           if (res.operation === 'create')
                           {
                              signalChatSendersThatIReceivedMessages(objectData);
                          }
                       }
                       else if (res.object === 'room')
                       {
                          //console.log('>> ////////// Wss received new room supportcase :: ' + JSON.stringify(res.objectData));
                          let objectData = res.objectData;
                          objectData.operation = res.operation;

                          //console.log('>>> App operation - room :: ' + res.operation);

                          setNewRoom(objectData);

                          setTimeout(() => {
                            setNewRoom(null);
                          },500);

                       }

                    }
                    else if (res.feature === "supportmessage")
                    {
                         //console.log('>>> ????? ++++++++ Message ACK: seen or received... :: ' + JSON.stringify(res));

                         if (res.operation === 'received')
                         {
                             if (res.uid !== undefined && res.uid !== null)
                             {

                                  setReceivedACK(res);
                             }
                         }
                         else if (res.operation === 'seen')
                         {
                             if (res.roomId !== undefined && res.roomId !== null)
                             {
                                  setSeenACK(res);
                             }
                         }

                    }
                    else if (res.categ === "all" || res.categ == "dreamer" || res.categ === "initiator")
                    {
                     let _yijCategs = countMemberCateg(res.requests);

                     setReloadPage(res.categ);
                     setReqdreamers(_yijCategs['dreamer'] === undefined ? 0:_yijCategs['dreamer']);
                     setReqinitiators(_yijCategs['initiator'] === undefined ? 0:_yijCategs['initiator']);
                     setReqelders(_yijCategs['elder'] === undefined ? 0:_yijCategs['elder']);
                   }
                   else
                   {
                     let _yijCategs = countMemberCateg(res.requests);

                     setReqdreamers(_yijCategs['dreamer'] === undefined ? 0:_yijCategs['dreamer']);
                     setReqinitiators(_yijCategs['initiator'] === undefined ? 0:_yijCategs['initiator']);
                     setReqelders(_yijCategs['elder'] === undefined ? 0:_yijCategs['elder']);
                   }



                   //console.log(`>>> YIJ Categs :: ${JSON.stringify(_yijCategs)}`);
              }
              else if (type === "actionlogs")
              {
                  //console.log('>>> changestate : reloadPage....');


                  setReloadPage("actionlogs");
              }
              else if (type === "members")
              {
                    //console.log(`Total members :: ${res.members.length}`);

                    let users = countAuths(res.users);
                    let yijMembers = countMembers(res.users);
                    let yijCategs = countMemberCateg(res.users);
                    let countries = countMemberCountries(res.users);

                    let _countries = Object.entries(countries)
                                           .sort(([,a],[,b]) => a-b)
                                           .reverse()
                                           .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

                     let yijCountries = Object.entries(_countries);

                     let rrdata = [
                       {
                         lat: -4.322447, //(Math.random() - 0.5) * 180,
                         lng: 15.307045, //(Math.random() - 0.5) * 360,
                         maxR: 5, //Math.random() * 20 + 3,
                         propagationSpeed: 2, //(Math.random() - 0.5) * 20 + 1,
                         repeatPeriod: 1000 //Math.random() * 2000 + 200
                       }
                     ];
                     let lat = -4.322447;
                     let lng = 15.307045;

                     let mldata = [
                       {
                         lat: -4.322447,
                         lng: 15.307045,
                         total: 17000000,
                         name: 'Kinshasa'
                       },
                       {
                         lat: 50.850340,
                         lng: 4.351710,
                         total: 1200000,
                         name: 'Brussels'
                       },
                       {
                         lat: 40.730610,
                         lng: -73.935242,
                         total: 8500000,
                         name: 'New York'
                       }
                     ];





                    setDebyooEmployees(users[-1] === undefined ? 0:users[-1]);
                    setVisitors(users[1] === undefined ? 0:users[1]);
                    setMembers(yijMembers[1] === undefined ? 0:yijMembers[1]);
                    setNonmembers(yijMembers[0] === undefined ? 0:yijMembers[0]);
                    setNotsignedins(users[0] === undefined ? 0:users[0]);
                    setMdreamers(yijCategs['dreamer'] === undefined ? 0:yijCategs['dreamer']);
                    setMinitiators(yijCategs['initiator'] === undefined ? 0:yijCategs['initiator']);
                    setMelders(yijCategs['elder'] === undefined ? 0:yijCategs['elder']);
                    setMcountries(yijCountries)

                    setRippleringsData(rrdata);
                    setRippleringsLat(lat);
                    setRippleringsLng(lng);

                 //   fetch('https://raw.githubusercontent.com/vasturiano/react-globe.gl/master/example/datasets/ne_110m_populated_places_simple.geojson').then(res => res.json())
                   //     .then(({ features }) => this.setState({maplabelsData: features}));


                    setTimeout(() => {
                      rrdata = [
                        {
                          lat: -4.322447, //(Math.random() - 0.5) * 180,
                          lng: 15.307045, //(Math.random() - 0.5) * 360,
                          maxR: 5, //Math.random() * 20 + 3,
                          propagationSpeed: 2, //(Math.random() - 0.5) * 20 + 1,
                          repeatPeriod: 1000 //Math.random() * 2000 + 200
                        },
                        {
                          lat: 50.850340, //(Math.random() - 0.5) * 180,
                          lng: 4.351710, //(Math.random() - 0.5) * 360,
                          maxR: 6, //Math.random() * 20 + 3,
                          propagationSpeed: 3, //(Math.random() - 0.5) * 20 + 1,
                          repeatPeriod: 900 //Math.random() * 2000 + 200
                        }
                      ];
                      lat = 50.850340;
                      lng = 4.351710;

                      //console.log('>> Goooo gdata Brussels....');

                      setRippleringsData(rrdata);
                      setRippleringsLat(lat);
                      setRippleringsLng(lng);

                    },10000);


                    setTimeout(() => {
                      rrdata = [
                        {
                          lat: -4.322447, //(Math.random() - 0.5) * 180,
                          lng: 15.307045, //(Math.random() - 0.5) * 360,
                          maxR: 5, //Math.random() * 20 + 3,
                          propagationSpeed: 2, //(Math.random() - 0.5) * 20 + 1,
                          repeatPeriod: 1000 //Math.random() * 2000 + 200
                        },
                        {
                          lat: 50.850340, //(Math.random() - 0.5) * 180,
                          lng: 4.351710, //(Math.random() - 0.5) * 360,
                          maxR: 6, //Math.random() * 20 + 3,
                          propagationSpeed: 3, //(Math.random() - 0.5) * 20 + 1,
                          repeatPeriod: 900 //Math.random() * 2000 + 200
                        },
                        {
                          lat: 40.730610, //(Math.random() - 0.5) * 180,
                          lng: -73.935242, //(Math.random() - 0.5) * 360,
                          maxR: 7, //Math.random() * 20 + 3,
                          propagationSpeed: 3, //(Math.random() - 0.5) * 20 + 1,
                          repeatPeriod: 800 //Math.random() * 2000 + 200
                        }
                      ];
                      lat = 40.730610;
                      lng = -73.935242;

                      //console.log('>> Goooo gdata New York....');

                      setRippleringsData(rrdata);
                      setRippleringsLat(lat);
                      setRippleringsLng(lng);


                    },15000);

                  //  console.log(`>>> Users :: ${JSON.stringify(users)}`);
                    //console.log(`>>> YIJ Members :: ${JSON.stringify(yijMembers)}`);
                   // console.log(`>>> YIJ Categs :: ${JSON.stringify(yijCategs)}`);
                    //console.log(`>>> YIJ Countries :: ${JSON.stringify(yijCountries)}`);
              }

         }

    }

    const signalChatSendersThatIReceivedMessages = async (message) => {

        try {

            let url = `https://bjhg2qil37.execute-api.us-east-1.amazonaws.com/prod/ihavereceivedmessages?uid=Debyoo&t=room&rid=${message.roomId}`;

            let response = await fetch(url, {method: 'POST'});

            let res = await response.json();

            console.log('>> ihavereceivedmessages res >> ' + JSON.stringify(res));

            return res;
        }
        catch(error)
        {
           return error;
        }

    }


    const loadYIJTotalRequests = (jwtToken) => {
    //var ip = generateIPForREST();

    //var jwtToken = localStorage.getItem("jwtToken") || '';
    //  this.props.setLanguage(responseData.country_code);
    fetch('https://api1.debyoo.com/load_yij_total_requests_api', {
                              method: 'POST',
                              headers: {
                                'Accept': 'application/json',
                                 'Authorization': jwtToken,
                                'Content-Type': 'application/json'
                              },
                               credentials: 'include',
                              body: JSON.stringify({
                                auth_check: 'jwt',
                                typef: 'global'
                              })
                      })
        .then((response) => response.json())
        .then((responseData) => {

             //alert(JSON.stringify(responseData))

               if (responseData.status == "success")
               {

                     setTotalRequests(responseData.totalRequests);
                     setTotalDreamers(responseData.totalDreamers);
                     setTotalInitiators(responseData.totalInitiators);
                     setTotalElders(responseData.totalElders);

               }


           })
         .catch(function(error) {

         }.bind(this));
    }

    const countAuths = (arr = []) => {
     const res = {};
     for(let i = 0; i < arr.length; i++){
        const { auth } = arr[i];
        if(res.hasOwnProperty(auth)){
           res[auth]++;
        }
        else{
           res[auth] = 1;
        };
     };
     return res;
   }


   const countMembers = (arr = []) => {
    const res = {};
    for(let i = 0; i < arr.length; i++){
       const { yijm } = arr[i];
       if(res.hasOwnProperty(yijm)){
          res[yijm]++;
       }
       else{
          res[yijm] = 1;
       };
    };
    return res;
  }


  const countMemberCateg = (arr = []) => {
   const res = {};
   for(let i = 0; i < arr.length; i++){
      const { yijmcateg } = arr[i];
      if(res.hasOwnProperty(yijmcateg)){
         res[yijmcateg]++;
      }
      else{
         res[yijmcateg] = 1;
      };
   };
   return res;
 }


  const countMemberCountries = (arr = []) => {
   const res = {};
   for(let i = 0; i < arr.length; i++){
      const { yijmcountry } = arr[i];
      if(res.hasOwnProperty(yijmcountry)){
         res[yijmcountry]++;
      }
      else{
         res[yijmcountry] = 1;
      };
   };
   return res;
 }



 const handleCheckWSConnection = async () => {

   console.log('>> App ws.readyState >>> ' + ws.readyState);

   if (ws !== undefined && ws.readyState !== 1)
   {

             setTimeout(() => {
               let user= JSON.parse(sinUser);

               authConnection(user);
             }, 500);


             setTimeout(async () => {
               try {

                   let url = `https://bjhg2qil37.execute-api.us-east-1.amazonaws.com/prod/ihavereceivedmessages?uid=Debyoo&t=all`;

                   let response = await fetch(url, {method: 'POST'});

                   let res = await response.json();

                   console.log('>> from YIJSupport ihavereceivedmessages res >> ' + JSON.stringify(res));

                   return res;
               }
               catch(error)
               {
                  return error;
               }
             }, 2000);
   }
   else
   {

       try {

           let url = `https://bjhg2qil37.execute-api.us-east-1.amazonaws.com/prod/ihavereceivedmessages?uid=Debyoo&t=all`;

           let response = await fetch(url, {method: 'POST'});

           let res = await response.json();

           console.log('>> from YIJSupport ihavereceivedmessages res >> ' + JSON.stringify(res));

           return res;
       }
       catch(error)
       {
          return error;
       }
   }

  }




  const handleOnKeyUp = (hash) => {

      clearTimeout(isTypingTimeout);

      //console.log('>>>> GOOOOOO TYPE ::: ' + JSON.stringify(hash));
      hash.action = "setIsTyping";
      hash.typing = true;

      try {
        ws.send(JSON.stringify(hash));
      } catch (error) {

      }

      isTypingTimeout = setTimeout(() => {

            hash.action = "setStoppedTyping";
            hash.typing = false;

            try {
              ws.send(JSON.stringify(hash));
            } catch (error) {

            }

      }, 4000);
  }





    //console.log('>> ---- sinUser >> ' + JSON.stringify(_authStatus));

       return (
         <Translation>
           {
               t =>
              <RecoilRoot>
               <BrowserRouter>
                 {isSignedin && !urlMatch && <HeaderConnectedPC sinUser={sinUser}/>}
                 <Routes>
                   <Route path="/" element={<PublicRoute isSignedin={isSignedin}><HomePage handleAuthConnection={handleAuthConnection}/></PublicRoute>} />
                   <Route path="/embed/:id" element={<EmbedPage isSignedin={isSignedin} sinUser={isSignedin ? sinUser:null}/>} />
                   <Route path="/pr/:id" element={<JobPage isSignedin={isSignedin} sinUser={isSignedin ? sinUser:null}/>} />
                   <Route path="/contact" element={<ContactPage isSignedin={isSignedin} sinUser={isSignedin ? sinUser:null}/>} />
                   <Route path="/support" element={<SupportPage isSignedin={isSignedin} sinUser={isSignedin ? sinUser:null}/>} />
                   <Route path="/terms" element={<TermsPage isSignedin={isSignedin} sinUser={isSignedin ? sinUser:null}/>} />
                   <Route path="/privacy" element={<PrivacyPage isSignedin={isSignedin} sinUser={isSignedin ? sinUser:null}/>} />
                   <Route path="/sf/:id" element={<SalesformPage isSignedin={isSignedin} sinUser={isSignedin ? sinUser:null}/>} />
                   <Route path="/preorder/:id" element={<PreorderPage isSignedin={isSignedin} sinUser={isSignedin ? sinUser:null}/>} />
                   <Route path="/payment/:id" element={<PaymentPage isSignedin={isSignedin} sinUser={isSignedin ? sinUser:null}/>} />
                   <Route path="/cancelpayment/:id" element={<CancelpaymentPage isSignedin={isSignedin} sinUser={isSignedin ? sinUser:null}/>} />
                   <Route path="/acceptpayment/:id" element={<AcceptpaymentPage isSignedin={isSignedin} sinUser={isSignedin ? sinUser:null}/>} />
                   <Route path="/declinepayment/:id" element={<DeclinepaymentPage isSignedin={isSignedin} sinUser={isSignedin ? sinUser:null}/>} />
                   <Route path="/home" element={
                       <ProtectedRoute isSignedin={isSignedin}>
                         <HomeFeedPage sinUser={sinUser} authStatus={authStatus} parentHash={parentHash}
                                       cardsResult={cardsResult} billingaddrsResult={billingaddrsResult} paymentsResult={paymentsResult} jobsResult={jobsResult} notificationsResult={notificationsResult}
                                       newMessage={newMessage} newRoom={newRoom} isTyping={isTyping}
                                       receivedACK={receivedACK} seenACK={seenACK} totalNotifications={totalNotifications}
                                       reloadPage={reloadPage} totalRequests={totalRequests} totalDreamers={totalRequests} totalInitiators={totalInitiators} totalElders={totalElders}
                                        openedTotal={openedTotal} resolvedTotal={resolvedTotal} debyooTotal={debyooTotal} userTotal={userTotal}
                                        debyooEmployees={debyooEmployees} visitors={visitors}
                                        members={members} nonmembers={nonmembers} notsignedins={notsignedins}
                                        mdreamers={mdreamers} minitiators={minitiators} melders={melders}
                                        reqdreamers={reqdreamers} reqinitiators={reqinitiators} reqelders={reqelders}
                                        mcountries={mcountries}
                                        rippleringsData={rippleringsData} rippleringsLat={rippleringsLat} rippleringsLng={rippleringsLng}
                                        maplabelsData={maplabelsData}
                                        handleCheckWSConnection={handleCheckWSConnection}
                                        handleOnKeyUp={handleOnKeyUp}
                                        reloadCards={reloadCards} reloadBillingaddrs={reloadBillingaddrs} reloadPayments={reloadPayments} reloadJobs={reloadJobs} reloadNotifications={reloadNotifications}
                                        handleResetUserNotifications={handleResetUserNotifications}
                                        iactiveposts={iactiveposts} ipendingposts={ipendingposts} iviewedposts={iviewedposts} iclickedposts={iclickedposts}/>
                      </ProtectedRoute>} />
                   <Route path="*" element={<NoMatch />} />
                 </Routes>
               </BrowserRouter>



             </RecoilRoot>
            }
        </Translation>
      );

}

export default App;
